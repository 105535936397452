import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../services/auth.guard";

import { AuthComponent } from "./auth.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SignupComponent } from "./signup/signup.component";
import { StaticPageComponent } from "./static-page/static-page.component";
import { StaticPageLicenseAgreementComponent } from "./static-page-license-agreement/static-page-license-agreement.component";
let code: any;
const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: LoginComponent,
    canActivate: [AuthGuard],

  },
  {
    path: "login",
    canActivate: [AuthGuard],
    component: LoginComponent,
  },
  {
    path: "forgot",
    component: ForgotComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "static-content",
    component: StaticPageComponent,
  },
  {
    path: "static-content-agreement",
    component: StaticPageLicenseAgreementComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
